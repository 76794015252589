import { useCallback, useEffect, useRef, useState } from "react";
import { useDebounce } from "./useDebounce";
import { getTableDataHelper } from "../helpers/getTableDataHelper";
import { defaulStateTable } from "../../constants/defaultStates";
import { uniqueItems } from "../helpers/objectHelpers";

export const useSelect = ({ externalData, extraItem, service, setCustomData, itemsCount = 50, delaySearch = 300, otherParams }) => {
  //Definir los estados necesarios en el hook, similares a los utilizados para crear una tabla
  const [selectLoading, setSelectLoading] = useState(false)
  const [customSelectData, setCustomSelectData] = useState(structuredClone(externalData || defaulStateTable))
  const [lastScroll, setLastScroll] = useState(0)
  const [lastScrollHeight, setLastScrollHeight] = useState({ normalLastScroll: 0, moreItemsLastScroll: null })
  const firstLoading = useRef(false)
  const firstData = useRef({ dataSource: [], metadata: {} })

  //Se llama al servicio indicando la cantidad inicial de elementos a traer y se le pasa el estado para actualizar el loading del select
  //Firstdata es para que cuando el valor a buscar sea '' retorne la primera data que se recuperó al montarse el select
  //setCustomData es opcional y su idea era que el estado de la data del select esté fuera de este y pueda utilizarse en otra parte
  const getInitialData = useCallback(async () => {
    let res = await service({ limit: itemsCount, setLoading: setSelectLoading, customSelectData: true, ...otherParams })

    const newLimit = parseInt(res?.data?.metadata?.quantity) - parseInt(itemsCount)
    if (newLimit > 0 && newLimit <= parseInt(itemsCount)) {
      res = await service({ setLoading: setSelectLoading, customSelectData: true, ...otherParams, skip: 0, limit: res?.data?.metadata?.quantity })
    }

    const { data, metadata } = getTableDataHelper({ data: res.data.data, metadata: res.data.metadata, isSelect: true })

    if (extraItem) {
      const newItem = data?.dataSource.find(item => item.id === extraItem.id)

      //Si el item extra no existe en la carga inicial entonces se agrega al array de datos de lo contrario se omite
      if (!newItem) data?.dataSource.unshift({ ...extraItem, value: extraItem.id, label: extraItem.name, key: 0 })
    }

    setCustomSelectData({
      ...structuredClone(defaulStateTable),
      dataTable: data?.dataSource,
      metadata
    })
    firstData.current = { dataSource: data?.dataSource, metadata }
    setCustomData && setCustomData(data?.dataSource)
    setSelectLoading(false)
  }, [service, setCustomData, setSelectLoading, itemsCount, otherParams, extraItem])

  //Se crea una funcion con el custom hook useDebounce para poder hacer un delay cuando se escribe en el select, y evitar hacer busquedas instantaneas
  //El servicio como tal requiere soportar los parámetros find_by y find_value para que funcione 
  //El tiempo es en milisegundos y puede ser personalizado
  const debounceItems = useDebounce(async ({ value = '' }) => {
    if (!value) {
      setCustomData && setCustomData(firstData.current)
      return setCustomSelectData(prev => ({
        ...prev,
        dataTable: firstData.current.dataSource,
        metadata: firstData.current.metadata,
        currentPage: 1,
        currentParams: null
      }))
    }
    let res = await service({ find_by: 'name', find_value: value.toLowerCase(), setLoading: setSelectLoading, limit: itemsCount, customSelectData: true, ...otherParams })
    //Verificar si se vuelve a llamar al endpoint para recuperar datos adicionales o no
    const newLimit = parseInt(res?.data?.metadata?.quantity) - parseInt(itemsCount)
    if (newLimit > 0 && newLimit <= parseInt(itemsCount)) {
      res = await service({ find_by: 'name', find_value: value.toLowerCase(), setLoading: setSelectLoading, customSelectData: true, ...otherParams, skip: 0, limit: res?.data?.metadata?.quantity })
    }
    const { data, metadata } = getTableDataHelper({ data: res.data.data, metadata: res.data.metadata, isSelect: true })
    const uniqueData = uniqueItems({ array: data?.dataSource, prop: 'id' })
    setCustomSelectData(prev => ({
      ...prev,
      metadata,
      dataTable: uniqueData,
      currentPage: 1,
      currentParams: { find_by: 'name', find_value: value.toLowerCase() }
    }))
    setCustomData && setCustomData(uniqueData)
    setSelectLoading(false)
  }, delaySearch)

  //Este evento llama a la función con debounce y le pasa el valor escrito en el select como prop, igual podrían pasarse otros valores si se requieren
  const onSearchItems = async ({ value }) => {
    debounceItems({ value })
  }

  //Este evento se utiliza para manejar la paginación asíncrona, que se llama cuando se llega al final o al inicio del scroll del select, pero se puede personalizar
  //Se verifica también si se ha llegado al limite de items del select verificando con el quantity si es mayor al numero de items actuales multiplicando la página actual por el limit
  //Una vez se llega, se forma un nuevo skip para así utilizarlo en el servicio, el last es opcional y su función era enfocada a las tablas realmente
  //El currentPage es el que identifica la paginación del select y se actualiza cada vez que se llega al final o al inicio del scroll
  const onMoreSelectItems = async ({ e, last }) => {
    if (selectLoading) {
      return
    }
    const scrollTop = e.target.scrollTop;
    const scrollHeight = e.target.scrollHeight;
    const clientHeight = e.target.clientHeight;
    const bottom = (scrollHeight - scrollTop - 1) <= (clientHeight - 1);
    const top = scrollTop <= 1;

    //Se determina con un booleano si se esta haciendo scroll hacia arriba o hacia abajo
    const direction = (scrollTop > (lastScroll || 0) || (scrollTop === lastScroll && scrollTop !== 0)) ? 'down' : 'up';
    setLastScroll(scrollTop);
    //La condicion se cumple si se esta haciendo scroll hacia abajo y todavia hay datos por recuperar o cuando se hace scroll hacia arriba y la página actual no es la primera
    if ((direction === 'down' && bottom && (customSelectData.metadata.quantity > customSelectData.currentPage * itemsCount)) ||
      (direction === 'up' && top && (customSelectData.currentPage > 1))) {
      const newPage = direction === 'down' ? customSelectData.currentPage + 1 : customSelectData.currentPage - 1;
      const newSkip = (newPage) * itemsCount - itemsCount;
      let limit = itemsCount


      setLastScrollHeight(prev => ({
        ...prev,
        normalLastScroll: !prev.normalLastScroll ? scrollHeight - clientHeight : scrollHeight - clientHeight < prev.normalLastScroll ? scrollHeight - clientHeight : prev.normalLastScroll,
        moreItemsLastScroll: null
      }));

      const newLimit = parseInt(customSelectData.metadata.quantity) - (parseInt(newSkip) + parseInt(customSelectData.metadata.limit))
      if (!newLimit || newSkip >= customSelectData.metadata.quantity || newSkip + parseInt(customSelectData.metadata.limit) > customSelectData.metadata.quantity) return
      //Verificar aqui si la cantidad de datos que faltan es menor que el limit para así traerse todos los datos al mismo tiempo
      if (newLimit !== 0 && newLimit < customSelectData.metadata.limit && parseInt(customSelectData.metadata.limit) === itemsCount) {
        limit = newLimit + parseInt(customSelectData.metadata.limit)
        setLastScrollHeight(prev => ({
          ...prev,
          moreItemsLastScroll: e.target.scrollTop,
        }));
      } else {
        limit = itemsCount;
      }
      const res = await service({ last, setLoading: setSelectLoading, limit, customSelectData: true, ...customSelectData.currentParams, skip: newSkip, ...otherParams });
      const { data, metadata } = getTableDataHelper({ data: res.data.data, metadata: res.data.metadata, isSelect: true });
      //De alguna forma filtrar para que si existe más un dato igual al extra data solo quedarse con uno
      const uniqueData = uniqueItems({ array: data?.dataSource, prop: 'id' })

      setCustomSelectData(prev => ({
        ...prev,
        currentPage: newPage,
        metadata,
        dataTable: uniqueData,
      }))
      setCustomData && setCustomData(uniqueData);

      if (direction === 'down') {
        e.target.scrollTop = 5; // Resetea el scroll hacia arriba
      }
      if (direction === 'up') {
        e.target.scrollTop = lastScrollHeight.moreItemsLastScroll ? lastScrollHeight.moreItemsLastScroll - 5 : lastScrollHeight.normalLastScroll < scrollHeight - clientHeight ? lastScrollHeight.normalLastScroll - 5 : scrollHeight - clientHeight - 5; // Resetea el scroll hacia abajo
      }
      setSelectLoading(false)
    }
  };

  //Una vez se monta el componente se llama al servicio pasado como prop del customSelect
  useEffect(() => {
    if (!service) return
    if (externalData && !firstLoading.current) {
      firstLoading.current = true
      return
    }
    getInitialData()
  }, [externalData, otherParams, getInitialData, service])

  return { onSearchItems, onMoreSelectItems, selectLoading, customSelectData }
}